import IMask from '../../vendor/node_modules/imask/dist/imask.min.js';

const phoneInputs = document.querySelectorAll("input[type='tel']");

[...phoneInputs].forEach( function (phoneInput){
  const inputInstructions = phoneInput.previousElementSibling;

  const mask = new IMask(phoneInput,{mask:'(000) 000-0000'});

  if (inputInstructions) {
    inputInstructions.addEventListener('animationend', function (event){
      event.currentTarget.classList.remove('warning')
    });
  
    phoneInput.addEventListener('keyup',function (event){
      const input = event.currentTarget;
      if (input.value.charAt(1) == 1){
        inputInstructions.classList.add('warning');
        input.value = "";
        mask.value = "";
      }
    });
  }
  
})

