import jquery from '../utils/jquery.min';
window.jQuery = jquery;
window.$ = jquery;


const telInputs = document.querySelectorAll('input[type="tel"]');

if (telInputs) {
  // Add event listeners to each tel input
  telInputs.forEach(input => {
    input.addEventListener('input', formatPhoneNumber);
    input.addEventListener('keydown', preventInvalidKeys);
  });
}

function formatPhoneNumber(e) {
  // Get input value and remove all non-digits
  let input = e.target;
  let value = input.value.replace(/\D/g, '');
  
  // Limit to 10 digits
  value = value.substring(0, 10);
  
  // Format the number as (XXX) XXX-XXXX
  let formattedNumber = '';
  if (value.length > 0) {
      formattedNumber = '(' + value.substring(0, 3);
      
      if (value.length > 3) {
          formattedNumber += ') ' + value.substring(3, 6);
      }
      
      if (value.length > 6) {
          formattedNumber += '-' + value.substring(6);
      }
  }
  
  // Update input value
  input.value = formattedNumber;
}

function preventInvalidKeys(e) {
  // Allow: backspace, delete, tab, escape, enter
  if ([8, 9, 13, 27, 46].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A, Ctrl+C, Ctrl+V
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
  }
  
  // Ensure that it is a number and stop the keypress if not
  if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && 
      (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
  }
}

const getStartedSection = document.querySelector('[data-component="getStarted"]');

if (getStartedSection) {
  
  const form = getStartedSection.querySelector('form');
  
  if (form) {
    // Make sure the last page of the 3 page form is visible
    const page3 = form.querySelector('[data-index="2"]');
    if (page3) {
      // check if the last page element doesnt have a data attribute "data-fui-page-hidden"
      if (!page3.hasAttribute('data-fui-page-hidden')) {
        form.addEventListener("submit", handleFormSubmission); 
      }
    }
  }

  

  function pushDataLayer() {
    let city = form.querySelector('[name="fields[city]"]').value;
    let state = form.querySelector('[name="fields[state]"]').value;
    let zipCode = form.querySelector('[name="fields[zip_code]"]').value;
    let buildZip = form.querySelector('[name="fields[building_zip_code]"]').value;
    let contactPreference = null;
    let purchasedInPast = 0;
    let tour = 0;
    let locationVisit = 0;
    let buildCategory = null;
    let buildUse = null;
    let buildStatus = null;
    let learnAbout = null;

    const farmSelector = form.querySelector('[name="fields[farm_building_use]"]');
    const suburbanSelector = form.querySelector('[name="fields[suburban_building_use]"]');
    const homeSelector = form.querySelector('[name="fields[homes_building_use]"]');
    const equestrianSelector = form.querySelector('[name="fields[equestrian_building_use]"]');
    const commercialSelector = form.querySelector('[name="fields[commercial_building_use]"]');
    const communitySelector = form.querySelector('[name="fields[community_building_use]"]');
    const buildingStatusSelector = form.querySelector('[name="fields[building_site_status]"]');
    const learnAboutSelector = form.querySelector('[name="fields[learn_about_morton_buildings]"]');

    if (form.querySelector('[name="fields[contact_preference]"]')) {
      contactPreference = form.querySelector('[name="fields[contact_preference]"]').value;
    }
    if (form.querySelector('[name="fields[previous_purchase]"]')) {
      purchasedInPast = form.querySelector('[name="fields[previous_purchase]"]').value;
    }
    if (form.querySelector('[name="fields[intersted_in_touring]"]')) {
      tour = form.querySelector('[name="fields[intersted_in_touring]"]').value;
    }
    if (form.querySelector('[name="fields[meeting_with_sales_consultant]"]')) {
      locationVisit = form.querySelector('[name="fields[meeting_with_sales_consultant]"]').value;
    }

    if (farmSelector && farmSelector.value !== '') {
      buildUse = farmSelector.value;
    } else if (suburbanSelector && suburbanSelector.value !== '') {
      buildUse = suburbanSelector.value
    } else if (homeSelector && homeSelector.value !== '') {
      buildUse = homeSelector.value
    } else if (equestrianSelector && equestrianSelector.value !== '') {
      buildUse = equestrianSelector
    } else if (commercialSelector && commercialSelector.value !== '') {
      buildUse = commercialSelector.value
    } else if (communitySelector && communitySelector.value !== '') {
      buildUse = communitySelector.value
    }

    if (form.querySelector('[name="fields[building_category]"]') !== null) {
      buildCategory = form.querySelector('[name="fields[building_category]"]').value; 
    }

    if (buildingStatusSelector && buildingStatusSelector.value !== '') {
      buildCategory = buildingStatusSelector.value; 
    }

    if (learnAboutSelector && learnAboutSelector.value !== '') {
      learnAbout = learnAboutSelector.value; 
    }

    // DataLayer update
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'contact morton',
        'e_action': 'lead submission',
        'e_label': city + ', ' + state,
        'zip_code': zipCode,
        'contactPreference': contactPreference,
        'purchasedInPast': purchasedInPast,
        'tour': tour,
        'locationVisit': locationVisit,
        'build_category': buildCategory,
        'build_use': buildUse,
        'build_zip': buildZip,
        'build_status': buildStatus,
        'learn_about': learnAbout
      });
    }
  }

  function handleFormSubmission(e) {
    pushDataLayer();
  }

}
